import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "timi-login-container" }
const _hoisted_2 = { class: "timi-login-content" }
const _hoisted_3 = { class: "timi-form-item" }
const _hoisted_4 = { class: "error-inline" }
const _hoisted_5 = {
  key: 0,
  class: "form-error form-error-inline"
}
const _hoisted_6 = {
  key: 1,
  class: "form-error form-error-inline"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "timi-form-item" }
const _hoisted_9 = { class: "error-inline" }
const _hoisted_10 = {
  key: 0,
  class: "form-error form-error-inline"
}
const _hoisted_11 = {
  key: 1,
  class: "form-error form-error-inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('route.login')), 1),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLoginUser && _ctx.handleLoginUser(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.$t('label.email')) + " ", 1),
            (_ctx.errorClass('email', _ctx.form.user)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('error.required')), 1))
              : _createCommentVNode("", true),
            (_ctx.errorClass('email', _ctx.form.user)=='wrongMail')
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('error.wrong_mail')), 1))
              : _createCommentVNode("", true)
          ]),
          _withDirectives(_createElementVNode("input", {
            ref: "inputUsername",
            type: "email",
            placeholder: _ctx.$t('placeholder.mail'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.user) = $event)),
            autocomplete: "username"
          }, null, 8, _hoisted_7), [
            [_vModelText, _ctx.form.user]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, [
            _createTextVNode(_toDisplayString(_ctx.$t('label.password')) + " ", 1),
            (_ctx.errorClass('password', _ctx.form.pass)=='required')
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('error.required')), 1))
              : (_ctx.errorClass('password', _ctx.form.pass)=='toShort')
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('error.pw_too_short')), 1))
                : _createCommentVNode("", true)
          ]),
          _createVNode(_component_PasswordInput, {
            onOnChange: _cache[1] || (_cache[1] = (value) => _ctx.form.pass = value),
            spellcheck: "false"
          })
        ]),
        _createVNode(_component_Button, {
          text: _ctx.$t('button.login'),
          primary: true,
          raised: true,
          onOnClick: _ctx.handleLoginUser,
          waitForResponse: _ctx.loading
        }, null, 8, ["text", "onOnClick", "waitForResponse"])
      ], 32),
      _createVNode(_component_router_link, {
        to: "/forgotpassword",
        tabindex: "-1"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.pw_forgotten')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}