
import { defineComponent } from 'vue';
import { Button, PasswordInput } from '@/ui/index';
import { LoginController } from '@/controller';

export default defineComponent({
    name: "Login",   
    components: { Button, PasswordInput },   
    data(){
        return{
            form:{
                user: "",
                pass: "",
                logintype: "login",
                pid: ""
            },
            loading: false,
            validateForm: false,
        }
    },
    created(){
        this.getStoragePID();
    },
    mounted() {
        this.$nextTick(() => {
            (this.$refs.inputUsername as HTMLElement).focus()            
        })
    },
    methods: {
        errorClass(fieldType: any, input: any) {
            if (this.validateForm) {
                if (input == "") {
                    return "required";
                }
                else if (fieldType == "email") {
                    var re = /\S+@\S+\.\S+/;
                    if (!re.test(input)) {
                        return "wrongMail";
                    }
                }
                else if (fieldType == "password") {
                    if (input.length < 8) {
                        return "toShort";
                    }
                }
            }
        },
        validate() {
            //NOL: Quick&Dirty Validation
            //check valid mail
            this.validateForm = true;
            var re = /\S+@\S+\.\S+/;
            if (!re.test(this.form.user)) {
                return false;
            }
            //check other fields
            if (this.form.pass.length >= 4 && this.form.user.length >= 3) {
                return true;
            }
            return false;
        },        
        async getStoragePID(){
            let res = await LoginController.getStoragePid();
            this.form.pid = res.data.pid;
        },
        async handleLoginUser(){
            if (this.validate()) {
                this.loading = true
                const res = await LoginController.loginUser(this.form);
                if (res?.data?.login){
                    this.$router.push("/overview");
                } else {
                    this.form.user = "";
                    this.form.pass = "";
                    (this.$refs.inputUsername as HTMLElement).focus()
                    this.$notify(this.$t('error.loginFailed'), { position: "right", type: "error" })
                }
                this.loading = false
            }
        }
    }
})
